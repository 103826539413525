<template>
  <div>
    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle del movimiento</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <concession-request-detail-dialog
      :id="item.id"
      v-model="openedEditDialog"
    />
  </div>
</template>

<script>
  import ConcessionRequestDetailDialog from './ConcessionRequestDetailDialog.vue'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ConcessionRequestActions',
    components: {
      ConcessionRequestDetailDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProducts',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchProducts',
        'deleteProducts',
        'activeProducts',
      ]),
      // async onDeactiveWarehouse () {
      //   await this.deleteProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
      // async onActiveWarehouse () {
      //   await this.activeProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
