var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-cart-arrow-down","title":"Solicitudes Despachos","color":"orange"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('concession-request-table-top')],1),_c('v-col',[_c('br'),_c('v-btn',{staticClass:"v-btn--example",attrs:{"color":_vm.showAdd ? 'red' : 'orange',"fab":"","large":""},on:{"click":function($event){_vm.showAdd = !_vm.showAdd}}},[(_vm.showAdd)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busca por cualquier campo","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.concessionsRequest,"loading":_vm.fetchingWarehouseConcessions,"search":_vm.search,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([(_vm.showAdd)?{key:"body.prepend",fn:function(ref){return [_c('br'),_c('tr',[_c('th'),_c('th',[_c('v-autocomplete',{attrs:{"label":"Productos","loading":_vm.fetchingProducts,"disabled":_vm.fetchingProducts,"items":_vm.getProducts,"item-text":"name","no-data-text":"No hay coincidencia","return-object":"","outlined":""},model:{value:(_vm.productSelected),callback:function ($$v) {_vm.productSelected=$$v},expression:"productSelected"}}),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","min":"1","rules":_vm.requiredFieldRules('Cantidad es requerido'),"outlined":"","label":"Rollos","required":""},model:{value:(_vm.packingCant),callback:function ($$v) {_vm.packingCant=$$v},expression:"packingCant"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","min":"1","rules":_vm.requiredFieldRules('Cantidad es requerido'),"outlined":"","label":"KG","required":""},model:{value:(_vm.cant),callback:function ($$v) {_vm.cant=$$v},expression:"cant"}})],1)],1)],1),_c('th',[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":"Observaciones","no-resize":""},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1),_c('th'),_c('th'),_c('th'),_c('th',[_c('v-btn',{staticClass:"transparent-button-icon",attrs:{"icon":"","type":"submit","color":"success","disabled":_vm.disabledSave,"loading":_vm.fetchingProducts},on:{"click":_vm.submit}},[_c('v-icon',{attrs:{"size":"28"}},[_vm._v(" mdi-check-all ")])],1)],1)])]}}:null,{key:"item.description",fn:function(ref){
          var input = ref.item;
return [(input.description)?_c('span',[_vm._v(" "+_vm._s(input.description)+" ")]):_c('span',[_vm._v(" ... ")])]}},{key:"item.total",fn:function(ref){
          var input = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(input.totalAmountCurrencyReference))+" ")])]}},{key:"item.tr",fn:function(ref){return [_c('span',[_vm._v(" 0 ")])]}},{key:"item.tk",fn:function(ref){return [_c('span',[_vm._v(" 0 ")])]}},{key:"item.createdAt",fn:function(ref){
          var input = ref.item;
return [_vm._v(" "+_vm._s(new Date(input.createdAt).getDate())+"/"+_vm._s(new Date(input.createdAt).getMonth() + 1)+"/"+_vm._s(new Date(input.createdAt).getFullYear())+" ")]}},{key:"item.approve",fn:function(ref){
          var transaction = ref.item;
return [(transaction.approved)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-all ")]):_c('v-icon',{attrs:{"color":"gray"}},[_vm._v(" mdi-check ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('concession-request-actions',{attrs:{"item":item}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }