<template>
  <v-container
    fluid
    tag="section"
  >
    <!-- {{ concessionsRequest }} -->
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-cart-arrow-down"
          title="Solicitudes Despachos"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <pre>{{ warehousesConcessions }}</pre> -->
          <v-row>
            <v-col cols="10">
              <concession-request-table-top />
            </v-col>
            <v-col>
              <br>
              <v-btn
                :color="showAdd ? 'red' : 'orange'"
                fab
                large
                class="v-btn--example"
                @click="showAdd = !showAdd"
              >
                <v-icon v-if="showAdd">
                  mdi-close
                </v-icon>
                <v-icon v-else>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-container>
          <v-data-table
            :headers="headers"
            :items="concessionsRequest"
            :loading="fetchingWarehouseConcessions"
            :search="search"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template
              v-if="showAdd"
              v-slot:[`body.prepend`]="{}"
            >
              <br>
              <tr>
                <th />
                <th>
                  <v-autocomplete
                    v-model="productSelected"
                    label="Productos"
                    :loading="fetchingProducts"
                    :disabled="fetchingProducts"
                    :items="getProducts"
                    item-text="name"
                    no-data-text="No hay coincidencia"
                    return-object
                    outlined
                  />
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="packingCant"
                        type="number"
                        min="1"
                        :rules="requiredFieldRules('Cantidad es requerido')"
                        outlined
                        label="Rollos"
                        required
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="cant"
                        type="number"
                        min="1"
                        :rules="requiredFieldRules('Cantidad es requerido')"
                        outlined
                        label="KG"
                        required
                      />
                    </v-col>
                  </v-row>
                </th>
                <th>
                  <v-textarea
                    v-model="data.description"
                    outlined
                    name="input-7-4"
                    label="Observaciones"
                    no-resize
                  />
                </th>
                <th />
                <th />
                <th />
                <th>
                  <v-btn
                    icon
                    class="transparent-button-icon"
                    type="submit"
                    color="success"
                    :disabled="disabledSave"
                    :loading="fetchingProducts"
                    @click="submit"
                  >
                    <v-icon size="28">
                      mdi-check-all
                    </v-icon>
                  </v-btn>
                </th>
              </tr>
            </template>
            <!-- <template v-slot:top> -->
            <!-- </template> -->
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.approve`]="{ item: transaction }">
              <!-- <span>{{transaction}}</span> -->

              <v-icon
                v-if="transaction.approved"
                color="green"
              >
                mdi-check-all
              </v-icon>

              <v-icon
                v-else
                color="gray"
              >
                mdi-check
              </v-icon>
              <!-- <h1>hioh</h1> -->
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <concession-request-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import ConcessionRequestTableTop from './ConcessionRequestTableTop'
  import ConcessionRequestActions from './ConcessionRequestActions.vue'
  import { TYPE_TRANSACTION } from '../../../../const'

  export default {
    name: 'ConcessionRequestTable',

    components: {
      ConcessionRequestTableTop,
      ConcessionRequestActions,
    },

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        isValidData: false,
        step: 1,
        salesUnits: ['UNIDAD', 'KG', 'M', 'LB'],
        alert: false,
        productSelected: null,
        cant: 1,
        packingCant: 1,
        data: {
          relatedWarehouseId: null,
          description: '',
          products: [],
        },
        showAdd: false,
        search: '',
        headers: [
          {
            text: 'Fecha del movimiento',
            value: 'createdAt',
          },
          {
            text: 'Codigo',
            value: 'code',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          {
            text: 'Estatus de la solicitud',
            value: 'approve',
          },
          {
            text: 'Total Rollos',
            value: 'tr',
          },
          {
            text: 'Total KG',
            value: 'tk',
          },
          {
            text: 'Total',
            value: 'total',
          },
          // {
          //   text: 'Fecha del movimiento',
          //   value: 'createdAt',
          // },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingProducts',
        'products',
        'warehouses',
        'actionWarehouse',
        'fetchingWarehouses',
        'warehousesInventory',
        'fechingWarehouseInventory',
        'actionEnvironmentData',
        'actionsBusiness',
        'warehousesConcessions',
        'fetchingWarehouseConcessions',
      ]),

      concessionsRequest () {
        this.warehousesConcessions.forEach(element => {
          // // // // // // // // // // // // // // // // // // // console.log('el', element)
        })
        return this.warehousesConcessions.filter(item => item.typeTransactionId === TYPE_TRANSACTION.concesion)
      },

      saleUnit () {
        return this.productSelected !== null ? this.productSelected.salesUnit : '(unidad)'
      },

      packingUnit () {
        return this.productSelected !== null ? this.productSelected.packingUnit : '(empaque)'
      },

      getProducts () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('movement store products', this.products)
        let res = this.products.filter(item => item.status)
        res = res.map(item => {
          item.name = item.codigo + ' - ' + item.description
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })
        return res
      },

      getCantbySelectProduct () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('productId', this.productSelected?.productId)
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('res', res)
        res = res[0]?.cantidad

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('cP', chargedProduct)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getPrincipalWarehousesId () {
        return this.warehouses.filter(item => item.status && item.principal)[0].id
      },

      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      total () {
        let total = 0
        this.data.products.forEach((item, i) => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item)
          total = total + (item.bigWholesalePrices * item.cant)
        })
        return total
      },

      disabledSave () {
        let res = false
        // for (const [key] of Object.entries(this.data)) {
        //   res = this.data[key] === false || this.data[key] === null
        // }
        if (!(this.productSelected && this.cant)) {
          res = true
        }
        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarehouseConcessions',
        'createWarehouseConcessions',
      ]),

      addProductList () {
        // if (this.cant <= this.getCantbySelectProduct) {
        if (this.productSelected && this.cant) {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
          if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
            this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
              ...item,
              cant: Number(item.cant) + Number(this.cant),
              packingCant: Number(item.packingCant) + Number(this.packingCant),
            }) : item)
          } else {
            this.data.products.push({ ...this.productSelected, cant: this.cant, packingCant: this.packingCant })
          }
        }
        this.alert = false
        this.productSelected = null
        this.cant = 1
        this.packingCant = 1
        // } else {
        //   this.alert = true
        // }
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.id !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },

      assingMax () {
        this.cant = this.getCantbySelectProduct
      },

      resetData () {
        // for (const [key] of Object.entries(this.data)) {
        //   this.data[key] = '' // Making all keys of the object ''
        // }

        this.data = {
          relatedWarehouseId: null,
          description: '',
          products: [],
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.showAdd = false
        this.resetData()
        // await this.fetchWarehouseMovements({ id: this.actionWarehouse })
        await this.fetchWarehouseConcessions({ id: this.actionWarehouse })
      },
      async submit (e) {
        e.preventDefault()
        // const businessId = this.businessId
        const total = this.total
        if (this.businessId !== null && this.disabledSave === false) {
          const submit = {
            ...this.data,
            typeTransactionId: TYPE_TRANSACTION.concesion,
            userId: this.user.id,
            warehouseId: this.getPrincipalWarehousesId,
            relatedWarehouseId: this.actionWarehouse,
            total: total,
            code: this.actionEnvironmentData.code,
            sucursalId: this.actionsBusiness,
          }

          await this.createWarehouseConcessions(submit)

          this.afterCreate()
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
